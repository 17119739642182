// extracted by mini-css-extract-plugin
export var bg = "qr-menu-maker-module--bg--87458";
export var ctaBtn = "qr-menu-maker-module--cta-btn--57bb8";
export var customButtons = "qr-menu-maker-module--custom-buttons--6da3b";
export var downloadBtn = "qr-menu-maker-module--download-btn--339bc";
export var orangeText = "qr-menu-maker-module--orange-text--657c3";
export var qrMenuBlock = "qr-menu-maker-module--qr-menu-block--70886";
export var qrMenuBlockContent = "qr-menu-maker-module--qr-menu-block-content--6b9cf";
export var qrMenuBlockRight = "qr-menu-maker-module--qr-menu-block-right--649a0";
export var subtitle = "qr-menu-maker-module--subtitle--d8c5a";
export var title = "qr-menu-maker-module--title--b51ef";
export var titleBadge = "qr-menu-maker-module--title-badge--9b764";
export var trialButton = "qr-menu-maker-module--trial-button--0e00d";