import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./post-small.module.css";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const PostSmall = ({ post, title, excerpt, featuredImage, date, url }) => {
  const bp = useBreakpoint();
  const image = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.altText || ``,
  };
  return (
    <>
      <Link to={post.uri} className={`${s.articleItem} h-100 text-decoration-none`}>
        <div className={`${s.postItem}`}>
          <div className="row">
            <div className="col-7 col-sm-7 pr-2">
              <div className="d-flex align-items-center flex-wrap">
                <span className={s.postDate}>{post.date}</span>
              </div>
              <Link to={post.uri} className={s.postTitle}>
                <h3>{parse(post.title)}</h3>
              </Link>
            </div>
            <div className="col-5 col-sm-5 pl-1">
              {image?.data && (
                <GatsbyImage
                  loading="eager"
                  placeholder="dominantColor"
                  image={image.data}
                  alt={image.alt}
                  className={s.postImg}
                />
              )}
            </div>
            <div className="col-12">
              <div className="d-flex align-items-center flex-wrap mt-3">
                {post?.categories?.nodes?.length > 0 && <div className={`${s.postCategories} mt-0`}>
                  {post?.categories?.nodes?.map((cat) => {
                    if ('Blog' == cat?.name) {
                      return null
                    }
                    return <Link to={cat?.uri} key={cat?.uri}>{cat?.name}</Link>
                  })}
                </div>}
                {!!post?.readingTime?.text && <div className={s.readTime}><svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.99974 0.42627C13.4589 0.42627 17.0737 4.0411 17.0737 8.50023C17.0737 12.9593 13.4589 16.5742 8.99974 16.5742C4.54061 16.5742 0.925781 12.9593 0.925781 8.50023C0.925781 4.0411 4.54061 0.42627 8.99974 0.42627ZM7.53174 3.36225C7.12658 3.36225 6.79775 3.69108 6.79775 4.09625V9.96822C6.79775 10.3734 7.12658 10.7022 7.53174 10.7022H11.9379C12.3431 10.7022 12.6719 10.3734 12.6719 9.96822C12.6719 9.56305 12.3431 9.23422 11.9379 9.23422H8.26574V4.09625C8.26574 3.69108 7.93691 3.36225 7.53174 3.36225Z" fill="#FF8A00" />
            </svg><span>{post?.readingTime?.text}</span></div>}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default PostSmall;
