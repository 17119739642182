import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import * as s from "./lastNews.module.css";
import { Link } from "gatsby";
import PostSmall from "../../post/post-small";


const LastNews = ({ title, items, hideSlider }) => {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(sort: { fields: [date], order: DESC }, limit: 3, skip: 0) {
        nodes {
          excerpt
          uri
          date(formatString: "MMMM DD, YYYY")
          title
          excerpt
          readingTime {
            text
          }
          featuredImage {
            node {
              gatsbyImage(width: 600,quality: 80)
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData( quality: 80 )
                }
              }
            }
          }
          categories {
            nodes {
              name
              uri
            }
          }
        }
      }
    }
  `);
  let posts = [];
  if (!items?.nodes?.length) {
    posts = data.allWpPost.nodes;
  } else {
    posts = items.nodes;
  }

  const defaultTitle = "Our Latest Blog Posts";

  if (!posts.length) {
    return <></>;
  }


  return (
    <>
      <section className={`${s.blogBlock} ${s.sidebar}`}>
        <div className="container px-0">
          <div className="row">
            <div className="col-12">
              <h2 className={s.blogTitle}>
                {title !== undefined ? title : defaultTitle}
              </h2>
            </div>
          </div>
        </div>
        
        <div className="container px-0">
          <div className="row">
            <div className="col-12 px-0">
                {posts.map(post => {
                  return (
                    <div className="col-12 px-2 px-md-3 mb-3" key={post.uri}>
                      <PostSmall post={post} />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LastNews;
