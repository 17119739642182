// extracted by mini-css-extract-plugin
export var desktopImage = "PDFDownloadBlockMobile-module--desktopImage--624d8";
export var inputWrapper = "PDFDownloadBlockMobile-module--input-wrapper--a674d";
export var invalid = "PDFDownloadBlockMobile-module--invalid--5fb68";
export var labelCheckboxGroup = "PDFDownloadBlockMobile-module--label-checkbox-group--1563d";
export var labelInputGroup = "PDFDownloadBlockMobile-module--label-input-group--710c4";
export var mobileImage = "PDFDownloadBlockMobile-module--mobile-image--fbf49";
export var pdfDownloadBlock = "PDFDownloadBlockMobile-module--pdf-download-block--37bc2";
export var sendButton = "PDFDownloadBlockMobile-module--send-button--a3925";
export var subtitle = "PDFDownloadBlockMobile-module--subtitle--e3037";
export var title = "PDFDownloadBlockMobile-module--title--cfbb0";