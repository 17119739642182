// extracted by mini-css-extract-plugin
export var authorSocials = "blog-post-module--author-socials--a891d";
export var beadcrumbItem = "blog-post-module--beadcrumb-item--baab2";
export var blogLink = "blog-post-module--blogLink--179be";
export var breadcrumbs = "blog-post-module--breadcrumbs--56168";
export var categories = "blog-post-module--categories--c992d";
export var category = "blog-post-module--category--0eca5";
export var feauturedImage = "blog-post-module--feauturedImage--331e3";
export var heading = "blog-post-module--heading--f6bf7";
export var postAuthorImg = "blog-post-module--post-author-img--0a36d";
export var postAuthorName = "blog-post-module--post-author-name--50226";
export var postAuthorWrapper = "blog-post-module--post-author-wrapper--d22a7";
export var postContent = "blog-post-module--post-content--e4527";
export var postDate = "blog-post-module--post-date--13288";
export var postFooter = "blog-post-module--post-footer--aae08";
export var postLine = "blog-post-module--post-line--7ad86";
export var readTime = "blog-post-module--read-time--14f9d";
export var shareBlock = "blog-post-module--share-block--864f9";
export var sidebarMobile = "blog-post-module--sidebarMobile--85a1b";
export var singleNavigation = "blog-post-module--single-navigation--e6231";
export var socials = "blog-post-module--socials--221c7";
export var tagsWrapper = "blog-post-module--tags-wrapper--23154";