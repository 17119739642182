// extracted by mini-css-extract-plugin
export var desktopImage = "PDFDownloadBlock-module--desktopImage--96e20";
export var inputWrapper = "PDFDownloadBlock-module--input-wrapper--fc2bc";
export var invalid = "PDFDownloadBlock-module--invalid--df3de";
export var labelCheckboxGroup = "PDFDownloadBlock-module--label-checkbox-group--4c473";
export var labelInputGroup = "PDFDownloadBlock-module--label-input-group--fb162";
export var mobileImage = "PDFDownloadBlock-module--mobile-image--3dca8";
export var pdfDownloadBlock = "PDFDownloadBlock-module--pdf-download-block--0f348";
export var sendButton = "PDFDownloadBlock-module--send-button--3cbbb";
export var subtitle = "PDFDownloadBlock-module--subtitle--b9f57";
export var title = "PDFDownloadBlock-module--title--420ea";