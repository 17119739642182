import React from "react";
import TrialButton from "./../../trialButton/trialButton";
import * as s from "./qr-menu-maker.module.css";
import { StaticImage } from "gatsby-plugin-image";

const QrMenuMaker = () => {

  const googlePlayUrl = "https://play.google.com/store/apps/details?id=com.qrmenumaker";
  const appStoreUrl = "https://apps.apple.com/us/app/qr-menu-maker/id1643857790";

  return (
    <>
      <section className={`${s.qrMenuBlock} ${s.hideLine}`} >
        <div className="container">
          <div className="row justify-content-between">
            <div className={`col-12 col-xl-6 px-xl-0`}>
              <div className={`title text-left ${s.title}`}>
                <div className="d-flex align-items-center justify-content-center justify-content-xl-start flex-column flex-xl-row" style={{ gap: '20px' }}>
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    width={75}
                    height={75}
                    // style={{ marginLeft: '-127px' }}
                    className="d-none d-md-block"
                    alt="title-logo"
                    src="./images/title-logo.png" />
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    width={55}
                    height={55}
                    className="d-md-none"
                    alt="title-logo"
                    src="./images/title-logo.png" />
                  <div className="text-center text-xl-left">
                    QR Menu Maker App
                    <br /> Fast, Easy & Free!
                  </div>
                </div>
              </div>
              <h3 className={`${s.subtitle}`}>A Digital Menu For The Digital Age</h3>
              <div className="d-flex justify-content-center justify-content-xl-start"><a href='https://partners.qrmenu.orders.co' target='_blank' className={s.ctaBtn}>Try now on web</a></div>
              <div className={`${s.customButtons} d-none d-xl-inline-block`}>
                <h4>Download for mobile for free</h4>
                <div className="d-flex justify-content-center justify-content-xl-start mt-3 pt-0 mt-xl-2 pt-xl-1" style={{ gap: '8px' }}>
                  <a className={`${s.downloadBtn} bg-orange rounded-xl btn-shadow`} href={googlePlayUrl} target="_blank" rel="nofollow">
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="get-on-google-play"
                      // src="./images/get-on-google-play-white.png"
                      src="./images/playmarket.png"
                      />
                  </a>
                  <a className={`${s.downloadBtn} bg-orange rounded-xl btn-shadow`} href={appStoreUrl} target="_blank" rel="nofollow">
                    <StaticImage
                      placeholder="none"
                      loading="eager"
                      alt="download-on-the-app-store"
                      // src="./images/download-on-the-app-store-white.png"
                      src="./images/appstore.png"
                      />
                  </a>
                </div>
              </div>
            </div>
            <div className={`col-12 col-xl-6 text-center ${s.qrMenuBlockRight}`} >
              <StaticImage
                placeholder="none"
                loading="eager"
                alt="digital-menu"
                // src="../images/QRMenu/digital-menu.png"
                // src="./images/qr-menu-maker-new.png"
                src="./images/mobile.png"
                style={{maxWidth: '400px'}}
              />
            </div>
            <div className={`${s.customButtons} d-xl-none col-12 text-center`}>
              <h4>Download for mobile for free</h4>
              <div className="d-flex justify-content-center mt-3 pt-0 mt-xl-2 pt-xl-1" style={{ gap: '28px' }}>
                <a className={`${s.downloadBtn} bg-orange rounded-xl btn-shadow`} href={googlePlayUrl} target="_blank" rel="nofollow">
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="get-on-google-play"
                    src="./images/get-on-google-play-white.png" />
                </a>
                <a className={`${s.downloadBtn} bg-orange rounded-xl btn-shadow`} href={appStoreUrl} target="_blank" rel="nofollow">
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="download-on-the-app-store"
                    src="./images/download-on-the-app-store-white.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default QrMenuMaker;
