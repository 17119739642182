import React, { useState } from 'react';
import * as s from "./checkboxSelect.module.css"
const CheckboxDropdown = ({ options, selectedValues, onChange }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isOpen, setIsOpen] = React.useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const toggleOption = (option) => {
        const updatedOptions = selectedOptions.includes(option)
            ? selectedOptions.filter((item) => item !== option)
            : [...selectedOptions, option];
        setSelectedOptions(updatedOptions);
        onChange(updatedOptions);
    };

    return (<>
        {!!isOpen && <div className={s.selectOverlay} onClick={() => {
                setIsOpen(false)
            }}></div>}
        <div className={s.select}>
            <div className={s.selectedWrapper} onClick={() => {
                setIsOpen(true)
            }}>
                <div className={s.selectedItems}>
                    {selectedValues.map((item, key) => {
                        return <div onClick={(e) => { e.stopPropagation(); toggleOption(item) }} key={key} className={s.option}><span>{item}</span><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 1.00714L8.99286 0L5 3.99286L1.00714 0L0 1.00714L3.99286 5L0 8.99286L1.00714 10L5 6.00714L8.99286 10L10 8.99286L6.00714 5L10 1.00714Z" fill="white" />
                        </svg>
                        </div>
                    })}
                </div>
                <svg className={s.selectAnchor} width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 5L0 0H10L5 5Z" fill="#292D32" />
                </svg>
            </div>
            {isOpen && <div className={s.checkboxDropdown}>
                <div className={s.selectedWrapper} onClick={() => {
                    setIsOpen(false)
                }}>
                    <div className={s.selectedItems}>
                        {selectedValues.map((item, key) => {
                            return <div onClick={(e) => { e.stopPropagation(); toggleOption(item) }} key={key} className={s.option}><span>{item}</span><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 1.00714L8.99286 0L5 3.99286L1.00714 0L0 1.00714L3.99286 5L0 8.99286L1.00714 10L5 6.00714L8.99286 10L10 8.99286L6.00714 5L10 1.00714Z" fill="white" />
                            </svg>
                            </div>
                        })}
                    </div>
                    <svg className={s.selectAnchor} width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 5L0 0H10L5 5Z" fill="#292D32" />
                    </svg>
                </div>
                <div className={s.options}>
                {options.map((option) => (
                    <label key={option} onClick={() => toggleOption(option)}>
                        
                            {selectedOptions.includes(option) ? (
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.967773" width="14.0649" height="14.0649" rx="3.5" fill="#D34801" stroke="#D34801" />
                                    <path d="M6 12.8678L2 8.86777L3.4 7.46777L6 10.0678L12.6 3.46777L14 4.86777L6 12.8678Z" fill="white" />
                                </svg>
                            ) : (
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.967773" width="14.0649" height="14.0649" rx="3.5" stroke="#D34801" />
                                </svg>
                            )}
                        
                        <span>{option}</span>
                    </label>
                ))}
                </div>
            </div>
            }
        </div>
        </>
    );
};

export default CheckboxDropdown;